@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "blueprint.scss";

.logo {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

b {
    color: #8f99a8;
}

.usc-release-box {
    width: calc(33% - 20px);
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 20px;

    h2 {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

.sidebar {
    width: 20%;
    display: inline-block;
    vertical-align: top;
}

.content {
    display: inline-block;
    vertical-align: top;
    width: 80%;
    padding-left: 20px;
}

.main-navbar {
    margin-bottom: 10px;
}

.page {
    width: 80%;
    margin-left: 10%;
}

.bill-content {
    max-height: calc(100vh - var(--bill-content-y-position));
    overflow-y: scroll;
}

.home-page-unauthed {
    .bp5-card {
        h3 {
            margin-top: 0px;
        }
    }
    .bp5-callout {
        vertical-align: top;
        display: inline-block;
        margin-top: 20px;
        width: calc(33% - 10px);

        h3 {
            margin-top: 0px;
            margin-bottom: 0px;
        }

        & + .bp5-callout {
            margin-left: 20px;
        }
    }

    ul {
        margin-right: 20px;
    }
}

.section-tree div.bp5-tree-node-content {
    height: auto;
    min-height: 30px;
}
.section-tree div span {
    white-space: normal;
    cursor: pointer;
}

.link > div:first-child > span:nth-child(3) {
    cursor: pointer;
}

.search-count {
    float: left;
}

.search-pager {
    float: right;
    margin: 10px;
}

.search-pager-buttons button {
    margin-left: 1px;
}

.sidebar .bp5-section-card {
    padding: 0 0 10px 0;
    margin-bottom: 10px;
}

.sidebar,
.bp5-drawer {
    .collapse-controls {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .collapse-wrapper {
        margin-bottom: 10px;
    }

    .collapse-wrapper .collapse-section {
        margin-top: 10px;
        margin-left: 20px;
    }
}

.congress-link {
    float: right;
}

.learn-list {
    width: 80% !important;
    margin: 0 auto !important;

    .bp5-card {
        justify-content: space-between;
    }
}

.learn-content {
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.learn-content,
.about-us {
    line-height: 1.5em;

    h2,
    h3 {
        margin-bottom: 5px;
        margin-top: 30px;
        color: #bdadff !important;
    }

    b {
        color: #d69fd6 !important;
    }

    li {
        margin-bottom: 15px;
    }
}

#root:not(.bp5-dark) {
    .learn-content,
    .about-us {
        h2,
        h3 {
            color: #7961db !important;
        }

        b {
            color: #9d3f9d !important;
        }
    }
}

.home-page {
    .bp5-section.bp5-card + .bp5-section.bp5-card {
        margin-top: 20px;
    }
}

.section-bill-table {
    width: 100%;
    table-layout: fixed;

    th.fixed,
    td.fixed {
        width: 150px;
    }
}

.learn-nav {
    padding: 10px 20px !important;
}

.bill-versions {
    display: inline-flex;
}

.bill-versions .bp5-breadcrumb {
    font-size: 14px;
}

.content-added {
    background-color: #cdffd8;
}

.content-removed {
    background-color: #ffdce0;
    text-decoration: line-through;
    text-decoration-color: #ff576b;
}

.content-hash {
    border-width: 2px;
    border-style: dashed;
    border-color: rgba($color: #000000, $alpha: 0.5);
    margin-left: 0px;
    padding-left: 18px;
}

.content-date {
    background-color: #68c1ee;
}

.content-dollar {
    background-color: #d69fd6;
}

.bp5-dark {
    .content-added {
        background-color: #2f8842;
    }

    .content-removed {
        background-color: #882f2f;
    }

    .content-hash {
        border-color: #fff;
    }

    .content-date {
        background-color: #0c5174;
    }

    .content-dollar {
        background-color: #5c255c;
    }
}

.mobile-drawer {
    display: none;
}

.bill-versions {
    height: auto;
}

.mobile,
.mobile-flex {
    display: none;
}

.bp5-drawer {
    .legislator-profile {
        h3 {
            margin-left: 20px;
        }
    }
}

.bp5-section.page {
    & > .bp5-section-header {
        .bp5-section-header-left {
            padding: 25px 0 15px 0;

            h6 {
                font-size: 2em;
                line-height: 1em;
            }
        }
    }
}

.bp5-section.content {
    padding-left: 0px;
    margin-left: 20px;
    width: calc(80% - 20px);
}

.section-detail {
    margin-bottom: 5px;
    white-space: nowrap;

    .section-detail-label,
    .section-detail-value {
        display: inline-block;
    }

    .section-detail-label {
        font-weight: bold;
        margin-right: 5px;
    }

    .section-detail-value {
        width: 70%;

        .bp5-breadcrumbs {
            width: 100%;
        }
    }
}

.legislator-profile {
    .center {
        text-align: center;
    }

    .image {
        width: 200px; /* Set a fixed width */
        height: 200px; /* Set a fixed height */
        border-radius: 50%; /* Make it circular */
        overflow: hidden; /* Hide anything outside the circle */
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        background-color: #00000042;
        margin-bottom: 10px;

        svg {
            width: 150px;
            height: 150px;
        }

        img {
            width: 100%; /* Makes the image cover the container width */
            height: 100%; /* Makes the image cover the container height */
            object-fit: cover; /* Ensures the image covers the area without stretching */
        }
    }

    ul {
        margin-top: 0px;
    }

    .bp5-button {
        margin: 20px;
    }
}

.legislator-card {
    .bp5-callout {
        display: flex;
    }

    .details {
        margin-left: 10px;
    }

    .image {
        width: 75px; /* Set a fixed width */
        height: 75px; /* Set a fixed height */
        border-radius: 50%; /* Make it circular */
        overflow: hidden; /* Hide anything outside the circle */
        display: flex;
        background-color: #00000042;

        svg {
            width: 75px;
            height: 75px;
        }

        img {
            width: 100%; /* Makes the image cover the container width */
            height: 100%; /* Makes the image cover the container height */
            object-fit: cover; /* Ensures the image covers the area without stretching */
        }
    }
}

.dashboard-grid {
    flex-flow: wrap;
    place-content: stretch flex-start;
    align-items: flex-start;
    display: inline-flex;
    gap: 20px;

    .bp5-section {
        width: 100%;
        margin-bottom: 0px !important;
        margin-top: 0px !important;

        &.quarter {
            width: calc(25% - 10px)
        }

        &.third {
            width: calc(33% - 10px)
        }

        &.half {
            width: calc(50% - 10px)
        }

        &.two-third {
            width: calc(67% - 10px);
        }

        &.three-quarter {
            width: calc(75% - 10px);
        }
    }
}

@media (min-device-width: 768px) and (max-device-width: 1200px) {
    .page {
        width: 100%;
        margin: 0px;
    }

    .enrolled-button {
        display: none;
    }

    .search-sort .bp5-control-group {
        display: block;

        .bp5-html-select,
        .bp5-input-group {
            display: block;
        }

        .bp5-html-select {
            margin-bottom: 5px;
        }
    }

    .dashboard-grid {
        .bp5-section {
        }
    }
}

@media (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    .page {
        .sidebar {
            width: 30%;
        }

        .content {
            width: 70%;
        }

        .bp5-section.content {
            width: calc(70% - 20px);
        }
    }

    .section-bill-table {
        width: 100%;
        table-layout: initial;

        th.fixed,
        td.fixed {
            width: 80px;
        }

        th:not(.critical),
        td:not(.critical) {
            display: none;
        }
    }

    .dashboard-grid {
        .bp5-section {
            width: 100% !important;
        }
    }
}

@media (max-device-width: 812px) and (orientation: landscape) {
    .page {
        .sidebar {
            width: 30%;
        }

        .content {
            width: 70%;
        }

        .bp5-section.content {
            width: calc(70% - 20px);
        }
    }

    .section-bill-table {
        width: 100%;
        table-layout: initial;

        th.fixed,
        td.fixed {
            width: 80px;
        }

        th:not(.critical),
        td:not(.critical) {
            display: none;
        }
    }

    .dashboard-grid {
        .bp5-section {
            width: 100% !important;
        }
    }
}

@media (max-device-width: 768px) {
    .learn-list {
        width: 100% !important;
        margin: initial !important;
    }

    .home-page-unauthed {
        .bp5-callout {
            display: block;
            margin-top: 20px;
            margin-left: 0px !important;
            width: auto;
        }
    }

    .section-bill-table {
        width: 100%;
        table-layout: initial;

        th.fixed,
        td.fixed {
            width: auto;
            min-width: 60px;
        }

        th:not(.critical),
        td:not(.critical) {
            display: none;
        }
    }

    .bp5-navbar {
        .logo {
            display: none;
        }

        .bp5-navbar-divider {
            display: none;
        }
    }

    .bp5-section.content {
        margin-left: 0px;
        width: 100%;
    }

    .dashboard-grid {
        .bp5-section {
            width: 100% !important;
            margin-bottom: 20px !important;
        }
    }

    .mobile {
        display: block;
    }
    .mobile-flex {
        display: inline-flex;
    }

    .mobile-nav .bp5-button {
        height: 40px;
    }

    .usc-release-box {
        width: auto;
    }

    .bp5-drawer.bp5-position-right {
        width: 95% !important;

        & > .usc-content-section {
            .usc-content-subsection,
            .usc-content-paragraph,
            .usc-content-subparagraph {
                display: none;
            }

            .usc-content-subsection:has(.content-removed),
            .usc-content-subsection:has(.content-added) {
                display: block;
            }

            .usc-content-paragraph:has(.content-removed),
            .usc-content-paragraph:has(.content-added) {
                display: block;
            }

            .usc-content-subparagraph:has(.content-removed),
            .usc-content-subparagraph:has(.content-added) {
                display: block;
            }
        }
    }

    .mobile-drawer {
        display: inline-block;
        margin-left: 0px !important;
    }

    .mobile-nav {
        .bp5-button {
            justify-content: left;
            margin-left: 10px;
            margin-top: 10px;
        }
    }

    .desktop-nav {
        display: none;
    }

    .page {
        width: 100%;
        margin: 0px;

        .sidebar {
            display: block;
            width: 100%;

            .collapse-controls {
                display: none;
            }

            .collapse-wrapper {
                display: none;
            }
        }

        .content {
            margin-top: 20px;
            display: block;
            width: 100%;
            padding: 0px;
        }
    }

    .search-count,
    .search-pager {
        float: initial;
        display: block;
    }

    .bill-content-section {
        margin-left: 0px;
    }

    .bill-versions {
        max-width: 200px;
    }
}
